.font-small {
  font-size: 10px;
}
.font-small-1 {
  font-size: 14px;
}
.font-small-2 {
  font-size: 15px;
}
.font-small-3 {
  font-size: 16px;
}
.font-medium {
  font-size: 18px;
}
.font-medium-1 {
  font-size: 22px;
}
.font-medium-2 {
  font-size: 26px;
}
.font-medium-3 {
  font-size: 28px;
}
.font-large {
  font-size: 30px;
}
.font-large-1 {
  font-size: 40px;
}
.font-large-2 {
  font-size: 45px;
}
.font-large-3 {
  font-size: 60px;
}
section {
  --background-size-mq-large:cover;--background-size-mq-medium:cover;--background-size-mq-small:cover;--background-size-mq-extra-small:cover;
  --background-repeat-mq-large:no-repeat;--background-repeat-mq-medium:no-repeat;--background-repeat-mq-small:no-repeat;--background-repeat-mq-extra-small:no-repeat;
  background-image: var(--gradient-mq-large, none), var(--background-mq-large);
  background-position: var(--background-x-mq-large) var(--background-y-mq-large);
  background-repeat: var(--background-repeat-mq-large);
  background-size: var(--background-size-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  display: var(--display-mq-large, block);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  section {
    background-image: var(--gradient-mq-medium, none), var(--background-mq-medium);
    background-position: var(--background-x-mq-medium) var(--background-y-mq-medium);
    background-repeat: var(--background-repeat-mq-medium);
    background-size: var(--background-size-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    background-image: var(--gradient-mq-small, none), var(--background-mq-small);
    background-position: var(--background-x-mq-small) var(--background-y-mq-small);
    background-repeat: var(--background-repeat-mq-small);
    background-size: var(--background-size-mq-small);
  }
}
@media (max-width: 416px) {
  section {
    background-image: var(--gradient-mq-extra-small, none), var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  section {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  section {
    display: var(--display-mq-extra-small, block);
  }
}
@media (max-width: 1024px) {
  section {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.features {
  margin: 0 auto;
}
section.features .legal-copy {
  line-height: 12px;
}
section.logos h1,
section.logos h2,
section.logos h3,
section.logos h4,
section.logos h5,
section.logos h6 {
  --padding-right-mq-large:40px;--padding-right-mq-medium:40px;--padding-right-mq-small:40px;--padding-right-mq-extra-small:40px;
  --padding-left-mq-large:40px;--padding-left-mq-medium:40px;--padding-left-mq-small:40px;--padding-left-mq-extra-small:40px;
}
section.logos p {
  --padding-right-mq-large:40px;--padding-right-mq-medium:40px;--padding-right-mq-small:40px;--padding-right-mq-extra-small:40px;
  --padding-left-mq-large:40px;--padding-left-mq-medium:40px;--padding-left-mq-small:40px;--padding-left-mq-extra-small:40px;
}
